<template>
  <div class="container my-24 my-lg-32 user-select-none">
    <v-card class="py-32 px-16 p-xl-32">
      <h1 class="mb-5">СИСТЕМА ОНЛАЙН-ОПРОСОВ И 360 LINKIS.PRO</h1>
      <div class="mb-5">
        <p><strong>Система онлайн-опросов и 360 <a class="text-color-primary" href="https://Linkis.PRO" target="_blank">
          Linkis.PRO</a></strong> - платформа для управления персоналом на основе данных.</p>
        <p>Система Linkis.PRO разработана как сервис SaaS модель обслуживания, при которой пользователям платформы
          предоставляется готовое прикладное программное обеспечение, полностью обслуживаемое оператором сервиса.
          Доступ к функциям платформы предоставляется пользователям с клиентских устройств, через веб-браузер.</p>
        <p>Основное преимущество системы Linkis.PRO состоит в отсутствии затрат, связанных с установкой, обновлением и
          поддержкой работоспособности оборудования и работающего на нём программного обеспечения.</p>
        <p>Доступ к системе Linkis.PRO предоставляется через сеть Интернет по защищенному протоколу SSL, который
          обеспечивает защищенную передачу информации в Интернете.</p>
        <p>Система Linkis.PRO имеет интуитивно понятный визуальный пользовательский интерфейс с использованием
          терминологии для каждой предметной области.</p>
      </div>
      <div class="mb-5">
        <p><strong>Функциональность системы Linkis.PRO</strong> позволяет измерить человеческий капитал организации, а
          значит сделать его понятным и управляемым. В основе платформы - технологии машинного обучения. Платформа
          включает в себя две группы процессов:</p>
        <ul>
          <li class="pb-3">Оценка кандидатов и сотрудников. Оценка производится по комплексу параметров - компетенциям,
            ценностям,
            потенциалу, рискам (деструкторам), мотивации, выгоранию, интеллектуальным способностям.
          </li>
          <li>Исследования мнений сотрудников — измерение вовлеченности, удовлетворенности, приверженности,
            межфункционального взаимодействия, взаимодействия внутри команды, других аспекты опыта на работе.
          </li>
        </ul>
        <p>Система предназначена для следующих категорий конечных пользователей:</p>
        <ul>
          <li class="pb-3">Сотрудники организаций;</li>
          <li class="pb-3">Руководители организации;</li>
          <li>Администраторы организации.</li>
        </ul>
      </div>
      <h2 class="mb-3">ПРОДУКТЫ СИСТЕМЫ ОНЛАЙН-ОПРОСОВ И 360 LINKIS.PRO</h2>
      <h3 class="mb-5">Оценка потенциала тестом Potential in Focus (PiF)</h3>
      <div class="mb-5">
        <h4 class="mb-3">PiF создан для оценки потенциала</h4>
        <p>Модель потенциала PiF была разработана на основе 160 международных исследований в области рабочей
          эффективности и адаптации к новым условиям. Для валидизации модели были проведены два пилотных исследования
          на российских компаниях.</p>
        <h4 class="mb-3">PiF предсказывает результативность сотрудников</h4>
        <p>Исследование показало, что руководители с высоким баллом по PiF показывают рост результативности на 41% выше,
          чем менеджеры с низкими результатами.</p>
        <h4 class="mb-3">PiF предсказывает результаты центра оценки</h4>
        <p>Результаты PiF высоко коррелируют (на уровне 0,42) с другим методом оценки потенциала – центром оценки (ЦО).
          Сотрудники, получающие высокие оценки в ЦО, получают высокие баллы и по PiF.</p>
        <h4 class="mb-3">Ясный ответ о потенциале сотрудника</h4>
        <p>В результате оценки автоматически формируется отчёт, который содержит краткий вывод о потенциале сотрудника и
          описание всех составляющих потенциала.</p>
        <p>Вам не требуется специального образования или обучения для интерпретации и использования отчета в работе. Он
          прост и удобен для принятия кадровых решений.</p>
        <p>Посмотреть пример отчета: <a class="text-color-primary text-bold"
                                        href="https://drive.google.com/file/d/1Yhn1emhgXRHTIrGpQ0RzuBCe_2_Mk0u_/view"
                                        target="_blank">PDF</a></p>
      </div>
      <h2 class="mb-3">СТОИМОСТЬ ПРОДУКТОВ</h2>
      <h3 class="mb-5">PiF предоставляется по простой ценовой политике</h3>
      <div class="row">
        <div class="col-5 text-center d-flex align-items-center justify-content-center flex-column">
          <p class="text-bold">2 900 ₽</p>
          <p>Оценка одного человека</p>
          <p>В стоимость включены все отчёты и использование системы онлайн-опросов и 360 Linkis.PRO</p>
        </div>
        <div class="col-7">
          <v-table
            striped
            class="text-size-12 mb-16"
          >
            <template #thead>
              <v-table-tr class="bg-color-primary">
                <v-table-th class="text-color-light text-size-14 p-12">
                  Объем проекта (тыс. руб.)
                </v-table-th>

                <v-table-th class="text-color-light text-size-14 p-12">
                  Стоимость одного тестирования (руб.)
                </v-table-th>

                <v-table-th class="text-color-light text-size-14 p-12">
                  Накопительная скидка (%)
                </v-table-th>
              </v-table-tr>
            </template>

            <template #default>
              <v-table-tr
                v-for="(value, index) in priceTable"
                :key="index"
                class="align-top"
              >
                <v-table-td class="text-center">
                  {{ value.projectScope }}
                </v-table-td>

                <v-table-td class="text-center">
                  {{ value.costOfOne }}
                </v-table-td>

                <v-table-td class="text-center">
                  {{ value.discount }}
                </v-table-td>
              </v-table-tr>
            </template>
          </v-table>
        </div>
      </div>
      <h2 class="mb-3">ДОКУМЕНТАЦИЯ</h2>
      <v-table
        striped
        class="text-size-12 mb-16"
      >
        <template #default>
          <v-table-tr
            v-for="(document, index) in documents"
            :key="index"
            class="align-top"
          >
            <v-table-td>{{ document.desc }}</v-table-td>

            <v-table-td>
              <p
                v-for="(file, index) in document.files"
                :key="index"
              >
                <a
                  class="text-color-primary text-bold"
                  :href="file.link"
                  target="_blank"
                >
                  {{ file.name }}
                </a>
              </p>
            </v-table-td>
          </v-table-tr>
        </template>
      </v-table>
    </v-card>
  </div>
</template>

<script>
import { DEFAULT_LOGO_KEY } from '@constants/components'
import {
  VCard,
  VTable,
  VTableTd,
  VTableTh,
  VTableTr
} from '@components/base'

import pgfFunctional from '@assets/doc/functional.pdf'
import pgfInstanceDeploymentLinkisPro from '@assets/doc/instance-deployment-linkis-pro.pdf'
import pgfLifeCycle from '@assets/doc/life-cycle.pdf'
import pgfSystemCharacteristics from '@assets/doc/system-characteristics.pdf'
import pgfUserManualLinkisPro from '@assets/doc/user-manual-linkis-pro.pdf'

export default {
  name: 'Portfolio',
  components: {
    VCard,
    VTable,
    VTableTd,
    VTableTh,
    VTableTr
  },
  data () {
    return {
      priceTable: [
        {
          projectScope: '50-299',
          costOfOne: '2 900',
          discount: '-'
        },
        {
          projectScope: '300-499',
          costOfOne: '2 600',
          discount: '10'
        },
        {
          projectScope: '500-999',
          costOfOne: '2 450',
          discount: '15'
        },
        {
          projectScope: '1 000-1 999',
          costOfOne: '2 300',
          discount: '20'
        },
        {
          projectScope: '2 000-3 999',
          costOfOne: '2 150',
          discount: '25'
        },
        {
          projectScope: '4 000-9 999',
          costOfOne: '2 000',
          discount: '30'
        },
        {
          projectScope: '10 000-19 999',
          costOfOne: '1 850',
          discount: '35'
        },
        {
          projectScope: 'от 20 000',
          costOfOne: '1 700',
          discount: '40'
        }
      ],
      documents: [
        {
          desc: 'Функциональные характеристики системы онлайн-опросов и 360 Linkis.PRO',
          files: [
            {
              name: 'Функциональные характеристики.PDF',
              link: pgfFunctional
            }
          ]
        }, {
          desc: 'Установка и эксплуатация ПО',
          files: [
            {
              name: 'Инструкция по развертыванию экземпляра программного обеспечения.PDF',
              link: pgfInstanceDeploymentLinkisPro
            },
            {
              name: 'Инструкция для пользователя.PDF',
              link: pgfUserManualLinkisPro
            }
          ]
        }, {
          desc: 'Технические и эксплуатационные характеристики ПО',
          files: [
            {
              name: 'Технические и эксплуатационные характеристики.PDF',
              link: pgfSystemCharacteristics
            }
          ]
        }, {
          desc: 'Документация, содержащая описание процессов, обеспечивающих поддержание',
          files: [
            {
              name: 'Жизненный цикл развития и поддержки.PDF',
              link: pgfLifeCycle
            }
          ]
        }
      ]
    }
  },
  inject: ['global'],
  mounted () {
    this.global.logo.setLogo({
      url: DEFAULT_LOGO_KEY
    })
  }
}
</script>
